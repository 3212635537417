"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instrumentSans = void 0;
const instrument_sans_semi_bold_1 = require("../fonts/instrument-sans-semi-bold");
const instrument_sans_regular_1 = require("../fonts/instrument-sans-regular");
exports.instrumentSans = {
    headingXLarge: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 28,
        lineHeight: 38,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingLarge: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 22,
        lineHeight: 32,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingMedium: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 18,
        lineHeight: 28,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingSmall: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 14,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingXSmall: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 12,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: "uppercase",
    },
    body: {
        font: instrument_sans_regular_1.instrumentSansRegular,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    strong: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    caption: {
        font: instrument_sans_regular_1.instrumentSansRegular,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    captionStrong: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    button: {
        font: instrument_sans_semi_bold_1.instrumentSansSemiBold,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
};
