"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spectralMedium = void 0;
exports.spectralMedium = {
    familyName: "Spectral",
    weight: "500",
    style: "normal",
    ttfName: "Spectral-Medium",
    ttfFile: "spectral-medium.ttf",
    woff2File: "spectral-medium.woff2",
};
