"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spectral = void 0;
const spectral_medium_1 = require("../fonts/spectral-medium");
exports.spectral = {
    headingXLarge: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 36,
        lineHeight: 42,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingLarge: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 28,
        lineHeight: 44,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingMedium: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 22,
        lineHeight: 28,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingSmall: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 14,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingXSmall: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 12,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: "uppercase",
    },
    body: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    strong: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    caption: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    captionStrong: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    button: {
        font: spectral_medium_1.spectralMedium,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
};
