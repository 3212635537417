"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instrumentSansSemiBold = void 0;
exports.instrumentSansSemiBold = {
    familyName: "Instrument Sans",
    weight: "600",
    style: "normal",
    ttfName: "InstrumentSans-SemiBold",
    ttfFile: "instrument-sans-semi-bold.ttf",
    woff2File: "instrument-sans-semi-bold.woff2",
};
