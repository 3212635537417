"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instrumentSansRegular = void 0;
exports.instrumentSansRegular = {
    familyName: "Instrument Sans",
    weight: "400",
    style: "normal",
    ttfName: "InstrumentSans-Regular",
    ttfFile: "instrument-sans-regular.ttf",
    woff2File: "instrument-sans-regular.woff2",
};
